import React from "react";
import { Link } from "react-router-dom";
import BookNow from "../../components/BookNow";
import SectionTitle from "../../components/SectionTitle";
import icon2 from "./img/bamboo-icon.png";
import img2 from "./img/bamboo.png";
import icon3 from "./img/bff-icon.png";
import img3 from "./img/bff.png";
import icon4 from "./img/chicken-icon.png";
import img4 from "./img/chicken.png";
import icon6 from "./img/pizza-icon.png";
import img6 from "./img/pizza.png";
import shapes from "./img/shapes.png";
import icon5 from "./img/subs-zone-icon.png";
import img5 from "./img/subszone.png";
import icon1 from "./img/wings-icon.png";
import img1 from "./img/wings.png";
import img7 from "./img/NYPD_Pasta.png";

const data = [
	{
		img: img1,
		title: "Just Chicken Wings",
		subtitle: "Chicken Wings & Boneless Wings.",
		text: [
			"Tasty & Crispy, our chicken wings and boneless wings are amazing!",
			"The culinary process makes them delicious...",
			"Provided with six sauces...",
		],
		icon: icon1,
		link: "/brands/just-chicken-wings",
	},
	{
		img: img2,
		title: "The Imperial Bamboo",
		subtitle: "Chinese Food Style.",
		text: [
			"A combination of Chinese roots with an American taste...",
			"Our recipes combine traditional flavors...",
			"The menu includes popular Chinese dishes...",
		],
		icon: icon2,
		link: "/brands/the-imperial-bamboo",
	},
	{
		img: img3,
		title: "BFF",
		subtitle: "Burger & French Fries",
		text: [
			"Gourmet burgers and fries designed with a French touch.",
			"BFF’s menu has 7 unique burger creations...",
		],
		icon: icon3,
		link: "/brands/bff",
	},
	{
		img: img4,
		title: "Crazy Tasty Chicken",
		subtitle: "Tenders, Thighs, and Breast Fried Chicken.",
		text: [
			"A virtual brand designed for gas stations...",
			"The flavor of our fried chicken is made using our own secret blend...",
		],
		icon: icon4,
		link: "/brands/crazy-tasty-chicken",
	},
	{
		img: img5,
		title: "Sub’s Zone",
		subtitle: "Delicious Cold and Hot Subs.",
		text: [
			"Cold and toasted hoagies made with fresh ingredients...",
			"Customers can customize their hoagies...",
		],
		icon: icon5,
		link: "/brands/subs-zone",
	},
	{
		img: img6,
		title: "NYPD Pizza",
		subtitle: "Authentic New York Style Pizza",
		text: [
			"NYPD Pizza is inspired by New York pizza...",
			"NYPD Pizza menu includes 7 signature pizzas...",
		],
		icon: icon6,
		link: "/brands/nypd-pizza",
	},
	{
		img: img7,
		title: "NYPD Pasta",
		subtitle: "Authentic New York Style Pasta",
		text: [
			"NYPD Pasta offers recipes inspired by New York...",
			"The NYPD Pasta menu includes 7 signature pasta dishes...",
		],
		icon: icon6,
		link: "/brands/nypd-pasta",
	},
];

const Brands = () => {
	return (
		<>
			<section className="section-gap brands-inner-section">
				{data?.map(({ img, title, subtitle, text, icon, link }, i) => (
					<section
						className="position-relative overflow-hidden section-gap"
						key={i}
					>
						<img
							src={shapes}
							className={`shape ${i % 2 === 0 ? "odd" : ""}`}
							alt=""
						/>
						<div className="container">
							<div
								className={`row g-4 ${i % 2 === 0 ? "flex-row-reverse" : ""
									}`}
							>
								<div className="col-lg-6 text-center">
									<img
										src={img}
										alt={title}
										className="mw-100 mt-lg-5"
									/>
								</div>
								<div className="col-lg-6">
									<SectionTitle shapeColor title={title} text={subtitle} />
									<div className="text-contents">
										{text?.map((item, j) => (
											<p key={j}>{item}</p>
										))}
									</div>
									{icon && (
										<img src={icon} className="pt-3 mw-100" alt={title} />
									)}
									{/* Learn More Button with Existing Header Style */}
									<div className="mt-3">
									<Link className="cmn-btn " to={link} >
									<span>LEARN MORE</span>
									</Link>
									</div>
								</div>
							</div>
						</div>
					</section>
				))}
			</section>
			<BookNow />
		</>
	);
};

export default Brands;

