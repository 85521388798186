import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/SectionTitle";
import brandLogo from "../../pages/home/components/img/brands/Transparency.jpg"; 
import phoneImage from "../../pages/home/components/img/brands/phone-mockup.png";
import metric1 from "../../pages/home/components/img/brands/metric-1.png";
import metric2 from "../../pages/home/components/img/brands/metric-2.png";
import metric3 from "../../pages/home/components/img/brands/metric-3.png";
import menu1 from "../../pages/home/components/img/brands/menu-1.png";
import menu2 from "../../pages/home/components/img/brands/menu-2.png";
import menu3 from "../../pages/home/components/img/brands/menu-3.png";
import deliveryPartners from "../../pages/home/components/img/brands/delivery-partners.png";
import ctaImage from "../../pages/home/components/img/brands/cta-image.png";
import otherBrand1 from "../../pages/home/components/img/brands/brand-3.png";
import otherBrand2 from "../../pages/home/components/img/brands/brand-2.png";
import otherBrand3 from "../../pages/home/components/img/brands/brand-1.png";
import "./JustChickenWings.scss";
import videoFile from "../../pages/home/components/videos/JustChickenWings_B2_Script4_v05-Subbed.mp4"; // ✅ Correctly placed import

const JustChickenWings = () => {
  return (
    <div className="just-chicken-wings-page">
      
     {/* Hero Section */}
     <section className="hero-section">
        <div className="container text-center">
          <img src={brandLogo} alt="Just Chicken Wings Logo" className="brand-logo" />
          <h1>Bring Just Chicken Wings to your area</h1>

          {/* Video Section */}
          <div className="video-container">
            <video className="hero-video" controls>
              <source src={videoFile} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          
        </div>
      </section>
 

     {/* Business Value Proposition Section */}
<section className="business-section">
  <div className="container">
    <div className="business-content">
      <img src={phoneImage} alt="Order Just Chicken Wings" className="phone-mockup" />
      <div className="business-text">
        <h2>Increase your wing cravings</h2>
        <p>
          Just Chicken Wings brings crispy, flavorful wings straight to your 
          customers. A simple yet mouth-watering menu that satisfies every wing lover.
        </p>
      </div>
    </div>
  </div>
</section>


      {/* Key Metrics / Achievements Section */}
      <section className="metrics-section">
        <div className="container text-center">
          <div className="metrics">
            <img src={metric1} alt="Sales" />
            <p>1M+ Wings Sold</p>
          </div>
          <div className="metrics">
            <img src={metric2} alt="Customers" />
            <p>A Flavor You Can’t Resist!</p>
          </div>
          <div className="metrics">
            <img src={metric3} alt="Years in Business" />
            <p>4 Years of Success</p>
          </div>
        </div>
      </section>

      {/* Testimonial Section */}
      <section className="testimonial-section">
        <div className="container text-center">
          <blockquote>
            "Adding Just Chicken Wings to my restaurant was the best decision! 
            Sales doubled within 3 months!"
          </blockquote>
          <cite>– Alex, Boynton Beach Restaurant Owner</cite>
        </div>
      </section>

      {/* Popular Menu Items / Featured Dishes Section */}
      <section className="menu-section">
        <div className="container">
          <h2>Fan Favorites</h2>
          <div className="menu-items">
            <div className="menu-card">
              <img src={menu1} alt="Buffalo Wings" />
              <h3>BBQ Wings</h3>
              <p>Smoky, sweet BBQ wings with a tangy finish</p>
            </div>
            <div className="menu-card">
              <img src={menu2} alt="Garlic Parmesan Wings" />
              <h3>Garlic Parmesan Wings</h3>
              <p>Rich, buttery garlic parmesan coated wings.</p>
            </div>
            <div className="menu-card">
              <img src={menu3} alt="BBQ Wings" />
              <h3>BBQ Wings</h3>
              <p>Smoky, sweet BBQ wings with a tangy finish.</p>
            </div>
          </div>
        </div>
      </section>

   {/* Delivery Platforms Section */}
<section className="delivery-section">
  <div className="container">
    <div className="text-content">
      <h2>Available on Hey Jag Customer App</h2>
      <p>
        Just Chicken Wings is available exclusively on <strong>Hey Jag customer App</strong>, 
        the revolutionary delivery platform that offers lower fees, better service, 
        and direct support for restaurants. Customers can easily order their favorite 
        wings with faster delivery and seamless tracking.
      </p>
      <Link to="/delivery" className="cmn-btn">
          <span>Learn More</span></Link>
    </div>
    <div className="delivery-logos">
      <img src={deliveryPartners} alt="Delivery Partners on Hey Jag" />
      
    </div>
  </div>
</section>



     {/* Call-to-Action (CTA) Section */}
<section className="cta-section">
  <div className="container text-center">
    <h2>That’s a Wrap!</h2>
    <p>Start running Just Chicken Wings from your kitchen today.</p>
    <img src={ctaImage} alt="Join Now" className="cta-image" />  {/* Image with reduced size ✅ */}
    <a
      href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ3bx6kbQqtu-wVjgCL2H26Z16mSbWqgSAt5J-bVWbFdx6o_jy0Knt21nnGAvFeQfaJpx7LtZld7?gv=true"
      target="_blank"
      rel="noopener noreferrer"
      className="cmn-btn"
    >
      <span>Take The First Step</span>
    </a>
  </div>
</section>


      {/* Explore Other Brands Section */}
      <section className="other-brands-section">
        <div className="container">
          <h2>Not your flavor? Explore other brands:</h2>
          <div className="other-brands">
            <div className="brand-card">
              <img src={otherBrand1} alt="Brand 1" />
              <h3>BFF Burgers</h3>
              <Link to="/brands/bff" className="cmn-btn">
              <span>LEARN MORE</span></Link>
            </div>
            <div className="brand-card">
              <img src={otherBrand2} alt="Brand 2" />
              <h3>Imperial Bamboo</h3>
              <Link to="/brands/the-imperial-bamboo" className="cmn-btn">
              <span>LEARN MORE</span></Link>
            </div>
            <div className="brand-card">
              <img src={otherBrand3} alt="Brand 3" />
              <h3>NYPD Pizza</h3>
              <Link to="/brands/nypd-pizza" className="cmn-btn">
              <span>LEARN MORE</span></Link>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};

export default JustChickenWings;
